<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template v-slot:activator="{ on }">
			<v-btn icon color="primary" v-on="on">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('offers.mainInformation') }}</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-row>
						<v-col cols="12" sm="6">
							<v-text-field v-model="name.first" :label="$t('auth.firstName')" :counter="firstNameMaxLength" :rules="rules.name.first" outlined />
						</v-col>

						<v-col cols="12" sm="6">
							<v-text-field v-model="name.last" :label="$t('auth.lastName')" :counter="lastNameMaxLength" :rules="rules.name.last" outlined />
						</v-col>
					</v-row>
					<v-text-field v-model="email" :label="$t('auth.email')" :counter="emailMaxLength" :rules="rules.email" outlined />
					<v-select
						v-model="locale"
						:items="availableLanguages"
						item-text="name"
						item-value="key"
						:label="$t('auth.language')"
						:rules="rules.locale"
						return-object
						outlined
					/>
					<v-checkbox v-model="verified" :label="$t('users.verified')" outlined />
					<v-select
						v-model="userType"
						:items="availableUserTypes"
						item-text="name"
						item-value="key"
						:label="$t('users.userType')"
						:rules="rules.userType"
						return-object
						outlined
					/>
					<v-checkbox v-model="promotionalEmails" :label="$t('users.promotionalEmails')" outlined />
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" text @click="switchEditMode()">
					{{ $t('search.close') }}
				</v-btn>
				<v-btn color="blue darken-1" text @click="callUpdate()">
					{{ $t('settings.edit') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UserInfoForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			editMode: false,
			valid: false,
			name: {
				first: '',
				last: ''
			},
			email: '',
			locale: null,
			verified: false,
			userType: null,
			promotionalEmails: false,
			rules: {
				name: {
					first: [
						(v) => !!v || 'First name is required.',
						(v) => (v && v.length <= this.firstNameMaxLength) || `First name must be less than ${this.firstNameMaxLength} characters`
					],
					last: [
						(v) => !!v || 'Last name is required.',
						(v) => (v && v.length <= this.lastNameMaxLength) || `Last name must be less than ${this.lastNameMaxLength} characters`
					],
					email: [
						(v) => !!v || 'Email is required.',
						(v) => v.indexOf('@') !== 0 || 'Email should have a username.',
						(v) => v.includes('@') || 'Email should include an @ symbol.',
						(v) => v.indexOf('.') - v.indexOf('@') > 1 || 'Email should contain a valid domain.',
						(v) => v.includes('.') || 'Email should include a period symbol.',
						(v) => v.indexOf('.') <= v.length - 3 || 'Email should contain a valid domain extension.',
						(v) => /.+@.+\..+/.test(v) || 'Email must be valid',
						(v) => (v && v.length <= this.emailMaxLength) || `Name must be less than ${this.emailMaxLength} characters`
					],
					locale: [(v) => !!v || 'Language is required.'],
					userType: [(v) => !!v || 'User type is required.']
				}
			}
		}
	},
	computed: {
		firstNameMaxLength() {
			return 64
		},
		lastNameMaxLength() {
			return 128
		},
		emailMaxLength() {
			return 255
		},
		...mapGetters({
			user: 'users/user',
			availableLanguages: 'data/availableLanguages',
			availableUserTypes: 'data/availableUserTypes'
		})
	},
	created() {
		this.name = this.user.name
		this.email = this.user.email
		this.locale = this.user.locale
		this.verified = this.user.verified
		this.userType = this.user.type
		this.promotionalEmails = this.user.promotionalEmails
		this.fetchAvailableUserTypes()
		this.fetchAvailableLanguages().then(() => {
			this.loading = false
		})
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		callUpdate() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.updateUserInfo({
				firstName: this.name.first,
				lastName: this.name.last,
				email: this.email,
				locale: this.locale,
				verified: this.verified,
				userType: this.userType,
				promotionalEmails: this.promotionalEmails
			}).then(() => {
				this.switchEditMode()
				this.loading = false
			})
		},
		...mapActions('users', ['updateUserInfo']),
		...mapActions('data', ['fetchAvailableLanguages', 'fetchAvailableUserTypes'])
	}
}
</script>
